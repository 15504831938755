<!--
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2022-01-07 16:04:06
 * @LastEditors: 番茄
 * @LastEditTime: 2022-01-07 16:23:16
-->
<template>
  <div class='my-container'>
    嘿嘿嘿嘿🍅
    <p>- 2022.1.07</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang='scss' scoped>
.my-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  // background: rgb(255, 218, 49);
  // color: #fff;
  p {
    margin-top: 10px;
    padding-left: 25px;
  }
}
</style>